import './App.css';
import {useRef}     from 'react';
import AtiseRent     from './images/AtiseRent.jpg';
import Eletricista   from './images/Eletricista.jpg';
import Chaveiro      from './images/Chaveiro.jpg';
import Encanador     from './images/Encador.jpg';
import Vidraceiro    from './images/Vidraceiro.jpg';
import images        from './images/img.png';
import clube         from './images/tudo.png';
import logo        from './images/logo.png';
import quemsomos   from './images/inicio.png';
import rentcar      from './images/woman_.png';
import Etcar        from './images/woman_dr.png';
import bureal       from './images/17.jpg';
import bureal2       from './images/burial2.jpg';
import care         from './images/01.jpg';
import eletrico         from './images/eletrico22.jpg';
import care1        from './images/03.jpg';
import care2        from './images/03.jpg';
import care3        from './images/07.avif';
import care4        from './images/09.jpg';
import care5        from './images/10.jpg';
import beneficios        from './images/08.webp';
import horas24     from './images/04.jpg';
import medicare    from './images/05.webp';
import medicare1    from './images/14.jpg';
import medicare2    from './images/16.jpg';
import medicare3    from './images/18.jpg';
import medicare4    from './images/19.webp';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// ICONS
import { FaAddressCard } from "react-icons/fa";
import { FaChild } from "react-icons/fa";
import { FaHouseDamage } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { FaPlaceOfWorship } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { FaPushed } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

const addEventOnElements = function (elements, eventType, callback) {
  for (let i = 0, len = elements.length; i < len; i++) {
    elements[i].addEventListener(eventType, callback);
  }
}


const navbar = document.querySelector("[data-navbar]");
const navTogglers = document.querySelectorAll("[data-nav-toggler]");



const toggleNav = () => {
      navbar.classList.toggle("active");
      document.body.classList.toggle("nav-active");
}



addEventOnElements(navTogglers, "click", toggleNav);
const header = document.querySelector("[data-header]");
const backTopBtn = document.querySelector("[data-back-top-btn]");



function App() {

    const inputRef = useRef(null);

    function handleClick() {
      window.open("https://api.whatsapp.com/send/?phone=5521975841912&text=Olá," + inputRef.current.value + "");

}

return (



<>



<header class="header" data-header>
<div class="container">

<a href="#" class="logo">
      <img src={logo} width="150" height="37" alt="atise.com.br" />   
</a>

<nav class="navbar" data-navbar>

  <div class="navbar-top">
    <a href="#" class="logo">
      <img src={logo} width="150" height="37" alt="atise.com.br" />
      
    </a>

    <button class="nav-close-btn" aria-label="close menu" data-nav-toggler>
      <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
    </button>
  </div>

  <ul class="navbar-list">



    <li>
      <a href="#housecare" id="titleHomeWEBSITE"class="navbar-link hover-1" data-nav-toggler><FaHouseDamage /> Atise HouseCare</a>
    </li>

    <li>
      <a href="#burial" id="titleHomeWEBSITE"class="navbar-link hover-1" data-nav-toggler><FaPlaceOfWorship /> Atise Burial</a>
    </li>


    <li>
      <a href="#easyrent" id="titleHomeWEBSITE"class="navbar-link hover-1" data-nav-toggler><FaCar /> Atise Rent</a>
    </li>

    <li>
      <a href="#beneficios" id="titleHomeWEBSITE"class="navbar-link hover-1" data-nav-toggler><FaAddressCard /> Clube de Benefícios</a>
    </li>


  </ul>

  {/* <div class="navbar-bottom">

    <div class="profile-card">
      <img src="./assets/images/author-1.png" width="48" height="48" alt="Steven" class="profile-banner">

      <div>
        <p class="card-title">Hello Steven !</p>

        <p class="card-subtitle">
          You have 3 new messages
        </p>
      </div>
    </div>

    <ul class="link-list">

      <li>
        <a href="#home" class="navbar-link hover-1" data-nav-toggler>Home</a>
      </li>

      <li>
        <a href="#topics" class="navbar-link hover-1" data-nav-toggler>Atise Assist</a>
      </li>

      <li>
        <a href="#featured" class="navbar-link hover-1" data-nav-toggler>Telemedicina</a>
      </li>

      <li>
        <a href="#recent" class="navbar-link hover-1" data-nav-toggler>HouseCare</a>
      </li>

      <li>
        <a href="#recent" class="navbar-link hover-1" data-nav-toggler>Burial</a>
      </li>

      <li>
        <a href="#recent" class="navbar-link hover-1" data-nav-toggler>EasyRent</a>
      </li>


      <li>
        <a href="#recent" class="navbar-link hover-1" data-nav-toggler>RentCar</a>
      </li>

      <li>
        <a href="#recent" class="navbar-link hover-1" data-nav-toggler>Benefícios</a>
      </li>

    </ul>

  </div> */}

  <p class="copyright-text">
    Copyright 2024 © atise assist
    Desenvolvido por Gleison Silveira
  </p>

</nav>

<a href="tel:+552134051060" id="btn"><FaPhoneAlt /> (21)3405-1060</a> 

<button class="nav-open-btn" aria-label="open menu" data-nav-toggler>
  <ion-icon name="menu-outline" aria-hidden="true"></ion-icon>
</button>

</div>
</header>



<main>
<article>

{/* QUEM SOMOS*/}
{/* QUEM SOMOS*/}
{/* QUEM SOMOS*/}

<section class="hero" id="home" aria-label="home">
<div class="container">

  <div class="hero-content">

    <h1 class="headline headline-1 section-title" id="title">
      Nós somos a atise
    </h1>

    <p class="hero-text">
    Na Atise Assist, acreditamos que a segurança e o bem-estar dos nossos clientes estão em primeiro lugar. Fundada com o compromisso de oferecer assistência veicular 24 horas de alta qualidade, nossa missão é garantir que você nunca esteja sozinho na estrada. Seja um pneu furado, uma pane elétrica ou qualquer outra emergência, nossa equipe está pronta para ajudar.

Nossa jornada começou com uma visão clara: transformar momentos de dificuldade em experiências tranquilas e seguras. Sabemos que imprevistos acontecem, e é por isso que estamos disponíveis 24/7, oferecendo soluções rápidas e eficientes para que você possa seguir seu caminho sem preocupações.
    </p>

      <div class="input-wrapper" id="whatsapp">

      <input ref={inputRef} id="message" type="message" name="message" placeholder="Digite sua dúvida..." required
        class="input-field" autocomplete="off" />

      <button class="btn btn-primary" onClick={handleClick}>
        <span class="span">Conversar</span>
        <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
      </button>

    </div>

  </div>

  <div class="hero-banner">
    <img id="quemsomosIMG"src={quemsomos}alt="Atise é uma empresa especializada em projetar soluções práticas e econômicas para o lançamento de produtos no mercado, aumentando seu valor, impulsionando vendas e convertendo associados, cooperados ou afiliados, ao mesmo tempo em que os fideliza e proporciona uma experiência única. Explore nosso conteúdo e mergulhe nas infinitas possibilidades que nossos produtos oferecem para agregar valor e impulsionar suas conversões!"  />
  </div>

  

  

</div>
</section>


{/* QUEM SOMOS*/}
{/* QUEM SOMOS*/}
{/* QUEM SOMOS*/}

{/*#SOBRE*/}
{/*#SOBRE*/}
{/*#SOBRE*/}

<section class="topics" id="assist" aria-labelledby="topic-label">
<div class="container">

  <div class="slider" data-slider>
    <ul class="slider-list" data-slider-container>

    <li class="slider-item" >
        
        <a href="#" class="slider-card">

          <figure class="slider-banner img-holder" style={{Width:507, height:618}}>
            <img src={care} width="507" height="618" loading="lazy" alt="House Care"
              class="img-cover" />
          </figure>

          <div class="slider-content" id="SliderText">
            <span class="slider-title">House Care</span>

            <p class="slider-subtitle">Assistência Residencial</p>
          </div>

        </a>
      </li>
      <li class="slider-item">
        <a href="#" class="slider-card" >

          <figure class="slider-banner img-holder" style={{Width:507, height:618}}>
            <img src={beneficios} width="507" height="618" loading="lazy" alt="Assistência veicular 24h"
              class="img-cover" />
          </figure>

          <div class="slider-content" id="SliderText">
            <span class="slider-title">Clube de Benefícios </span>

            <p class="slider-subtitle">tudomaispormenos.com.br</p>
          </div>

        </a>
      </li>

      <li class="slider-item">
        <a href="#" class="slider-card" >

          <figure class="slider-banner img-holder" style={{Width:507, height:618}}>
            <img src={horas24} width="507" height="618" loading="lazy" alt="Assistência 24 horas"
              class="img-cover" />
          </figure>

          <div class="slider-content" id="SliderText">
            <span class="slider-title">AtiseRent</span>

            <p class="slider-subtitle">Aluguel de carro descomplicado</p>
          </div>

        </a>
      </li>

      <li class="slider-item">
        <a href="#" class="slider-card" >

          <figure class="slider-banner img-holder" style={{Width:507, height:618}}>
            <img src={medicare} width="507" height="618" loading="lazy" alt="Atendimento médico online"
              class="img-cover" />
          </figure>

          <div class="slider-content" id="SliderText">
            <span class="slider-title">Telemedicina</span>

            <p class="slider-subtitle">Atendimento médico online </p>
          </div>

        </a>
      </li>

      


    </ul>
  </div>

</div>

</section>

{/*#SOBRE*/}
{/*#SOBRE*/}
{/*#SOBRE*/}

{/*MEDCARE*/}
{/*MEDCARE*/}
{/*MEDCARE*/}
{/* 
<section class="section feature" aria-label="feature" id="telemedicina">
<div class="container">

  <h2 class="headline headline-2 section-title" id="title">
  <span class="span"><FaPushed /> MedCare</span>
  </h2>

  <p class="section-text">
    <span id="subTitle">Seja bem vindo a nossa Telemedicina</span>
  </p>

  <ul class="feature-list">

    <li>
      <div class="card feature-card">

        <figure class="card-banner img-holder" style={{Width:1602, height:1003}}>
          <img src={medicare}  width="1602" height="903" loading="lazy"
            alt="Telemedicina" class="img-cover" />
        </figure>

        <div class="card-content">

          <div class="card-wrapper">
            <div class="card-tag">
              <a href="#" class="span hover-2">Telemedicina :: </a>

            <a href="#" class="span hover-2">atendimento médico</a>
            </div>

            <div class="wrapper">

              <ion-icon name="time-outline" aria-hidden="true"></ion-icon>
              <span class="span" id="online">online</span>

            </div>
        
          </div>

          <h3 class="headline headline-3">
            <a href="#" class="card-title hover-2">
              Médicos online
            </a>
          </h3>
        </div>

      </div>
    </li>
    <li id="HIDE">
      <div class="card feature-card">

        <figure class="card-banner img-holder" style={{Width:1602, height:1003}}>
          <img src={medicare1}  width="1602" height="903" loading="lazy"
            alt="Self-observation is the first step of inner unfolding" class="img-cover" />
        </figure>

        <div class="card-content">

          <div class="card-wrapper">
            <div class="card-tag">
              <p  class="span hover-2">O que é a telemedicina? <br />A telemedicina é um processo avançado para monitoramento de pacientes, troca de informações médicas e análise de resultados de diferentes exames. Estes exames são avaliados e entregues de forma digital, dando apoio para a medicina tradicional.</p>
            </div>
          </div>
        </div>

      </div>
    </li>


  </ul>

</div>

</section> */}

{/*MEDCARE*/}
{/*MEDCARE*/}
{/*MEDCARE*/}

{/*HOUSE CARE*/}  
{/*HOUSE CARE*/}  
{/*HOUSE CARE*/}  
{/*HOUSE CARE*/}  

<section class="section feature" aria-label="feature" id="housecare">
<div class="container">

<h2 class="headline headline-2 section-title">
  <span class="span" id="title"><FaPushed /> Atise HouseCare</span>
</h2>

<p class="section-text" id="subTitle">
  Assistência Residencial
</p>

<ul class="feature-list">

  <li>
    <div class="card feature-card">

      <figure class="card-banner img-holder" style={{Width:1602, height:503}}>
        <img src={care5} width="1602" height="1203" loading="lazy"
          alt="Self-observation is the first step of inner unfolding" class="img-cover"/>
      </figure>

      <div class="card-content">
        <div id="textAlignGreen">
        <div id="SliderText" >
              <span class="slider-title"> Maximize seus lucros e fidelize seus clientes com a House Care!</span>
        </div>
        </div>
        <div class="card-wrapper">
          
          <div class="card-tag">
          
            <p id="">A House Care oferece assistência residencial 24h, proporcionando tranquilidade e segurança aos seus clientes. Com serviços rápidos e eficientes para emergências como problemas elétricos, hidráulicos e perda de chaves, sua empresa se destacará no mercado. A House Care não só aumenta a satisfação do cliente, como também melhora a retenção, reduzindo custos com a fidelização. Disponível 24 horas por dia, 7 dias por semana, nosso serviço de discagem gratuita (DDG-0800) garante suporte contínuo.  Invista na House Care e veja sua empresa crescer.
            </p>
          </div>
        </div>
      </div>

    </div>
  </li>
  <li id="HIDE">
    
    <div class="card feature-card" >
        <div id="textAlignGreen">
          <div  id="SliderText">
                <span class="slider-title"> Vidraceiro</span>
          </div>
        </div>
      <figure id="CARDIMAGES" class="card-banner img-holder">
        <img src={Vidraceiro} loading="lazy" class="img-cover"/>
      </figure>

      

    </div>
  </li>

  <li id="HIDE">
    <div class="card feature-card"  >
    <div id="textAlignGreen">
          <div  id="SliderText">
                <span class="slider-title"> Chaveiro</span>
          </div>
        </div>
      <figure class="card-banner img-holder">
        <img src={Chaveiro} loading="lazy"
          alt="Self-observation is the first step of inner unfolding" class="img-cover"/>
      </figure>

    </div>
  </li>
  <li id="HIDE">
    <div class="card feature-card">
    <div id="textAlignGreen">
          <div  id="SliderText">
                <span class="slider-title"> Eletricista</span>
          </div>
        </div>
      <figure class="card-banner img-holder" >
        <img src={Eletricista}  loading="lazy"
          alt="Self-observation is the first step of inner unfolding" class="img-cover"/>
      </figure>
    </div>
  </li>

  <li id="HIDE">
    <div class="card feature-card">
        <div id="textAlignGreen">
          <div  id="SliderText">
                <span class="slider-title">Encanador </span>
          </div>
        </div>
      <figure class="card-banner img-holder">
        <img src={Encanador}loading="lazy"
          alt="Self-observation is the first step of inner unfolding" class="img-cover"/>
      </figure>

    </div>
  </li>

</ul>

</div>

</section>

{/*HOUSE CARE*/}  
{/*HOUSE CARE*/}  
{/*HOUSE CARE*/}  
{/*HOUSE CARE*/} 

{/*BURIAL*/}
{/*BURIAL*/}
{/*BURIAL*/}
  
<section class="section feature" aria-label="feature" id="burial">
  <div class="container">

        <h2 class="headline headline-2 section-title" id="title">
          <span class="span"><FaPushed /> Atise Burial</span>
        </h2>

        <p class="section-text"  id="subTitle">
            Assistência Funeral
        </p>

        <ul class="feature-list">
          <li>
            <div class="card feature-card">

                <figure class="card-banner img-holder" style={{Width:1602, height:503}}>

                    <img src={bureal} width="1602" height="1203" loading="lazy"
                    alt="Self-observation is the first step of inner unfolding" 
                    class="img-cover"/>

                </figure>
                <div id="textAlignGreen">
                  <div  id="SliderText">
                     <span class="slider-title">Demonstre Cuidado e Fidelize Seus Clientes</span>
                  </div>
                </div>
              <div class="card-content">
                <div class="card-wrapper">
                  <div class="card-tag">
                    <p id="">Em tempos de profunda dor, o cuidado e o suporte são essenciais. Nosso serviço de Assistência Funeral oferece agilidade nos procedimentos necessários em situações inesperadas, em todo o Brasil. Disponível 24h pelo telefone 0800 719-0995, nossa equipe garante um sepultamento digno para seus clientes.

                    Adicionar esse serviço ao seu portfólio não só demonstra empatia e compromisso, mas também agrega valor à sua empresa, aumentando a retenção e a satisfação dos clientes.Ofereça tranquilidade e segurança com um investimento mínimo. Para mais detalhes sobre nossos planos, consulte-nos.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>


          <li id="HIDE">
            <div class="card feature-card">
                <div id="textAlignGreen">
                  <div  id="SliderText">
                     <span class="slider-title">Assistência Funeral</span>
                  </div>
                </div>
              <figure id="CARDIMAGES" class="card-banner img-holder">
                <img src={bureal2} width="1602" height="903" loading="lazy"
                  alt="Self-observation is the first step of inner unfolding" class="img-cover"/>
              </figure>
            </div>
          </li>


        </ul>
      </div>
    </section>
  </article>
</main>

{/*BURIAL*/}
{/*BURIAL*/}
{/*BURIAL*/}

{/* EASYRENT*/}
{/* EASYRENT*/}
{/* EASYRENT*/}

      <section class="hero" id="easyrent" aria-label="home" >
        <div class="container">

          <div class="hero-content">
            <h1 class="headline headline-1 section-title" id="title">
            <FaPushed />AtiseRent
            </h1>
            <div id="textAlignGreen">
                  <div  id="SliderText">
                     <span class="slider-title">Descubra o AtiseRent da Atise</span>
                  </div>
                </div>
            <p class="hero-text">
                Diga adeus à burocracia e aos perrengues na hora de alugar um carro. Com o AtiseRent, sua empresa oferece uma experiência de aluguel de veículos sem necessidade de cartão de crédito para caução, tornando o processo mais simples e rápido para seus clientes.

Nosso serviço é exclusivo para usuários ativos na nossa plataforma, garantindo segurança e confiabilidade. Com parcerias com as maiores locadoras do país, disponibilizamos uma vasta gama de veículos para atender às suas necessidades.

Não perca a chance de se destacar da concorrência e oferecer uma solução sem estresse para seus clientes. Entre em contato com a Atise hoje mesmo e descubra como o AtiseRent pode transformar sua operação!
            </p>

          </div>
          <div class="hero-banner" id="img_rent">
            <img src={rentcar} id="imgR"alt="  Diga adeus à burocracia e aos perrengues na hora de alugar um carro. Com o AtiseRent, sua empresa oferece uma experiência de aluguel de veículos sem necessidade de cartão de crédito para caução, tornando o processo mais simples e rápido para seus clientes.

Nosso serviço é exclusivo para usuários ativos na nossa plataforma, garantindo segurança e confiabilidade. Com parcerias com as maiores locadoras do país, disponibilizamos uma vasta gama de veículos para atender às suas necessidades.

Não perca a chance de se destacar da concorrência e oferecer uma solução sem estresse para seus clientes. Entre em contato com a Atise hoje mesmo e descubra como o AtiseRent pode transformar sua operação!" class="w-100"/>
          </div>
  </div>
</section>


{/* EASYRENT*/}
{/* EASYRENT*/}
{/* EASYRENT*/}

{/* EASYRENT*/}
{/* EASYRENT*/}
{/* EASYRENT*/}
{/* 
<section class="hero" id="rentcar" aria-label="home">
  <div class="container">
    <div class="hero-content">
      <p class="hero-subtitle"id="title"><FaPushed /> RentCar </p>
      <p class="hero-text">
        Já pensou em oferecer para seu cliente uma experiência inovadora e descomplicada de aluguel de veículo? Com nosso serviço RentCar, desenvolvido pela Atise, isso é possível. Nosso produto foi cuidadosamente projetado para atender às necessidades do seu público, seja para viagens planejadas ou para substituição de veículos em manutenção. Para usufruir desse benefício, seu cliente precisa estar cadastrado em nossa plataforma. Uma vez cadastrado, ele pode simplesmente ligar para nosso número 0800 e fazer a solicitação, sem nem precisar contatar a empresa à qual é cliente. Nossa equipe cuida de todo o processo, desde a reserva até a indicação do local mais próximo de uma locadora parceira para a retirada do veículo. Além disso, todos os veículos incluem taxa de seguro compulsório, proporcionando comodidade e segurança na contratação do serviço. Após a compensação do boleto, seu cliente pode se dirigir ao local indicado para retirar o veículo, sem a necessidade de caução com cartão de crédito. Essa experiência sem complicações certamente deixará seu cliente encantado, reforçando sua marca como sinônimo de qualidade e conveniência
      </p>
    </div>

    <div class="hero-banner" id="img_rent">
      <img src={Etcar} id="imgR"alt="Descubra o EasyRent - uma solução inovadora desenvolvida pela Atise para simplificar o processo de aluguel de carros para empresas e seus clientes. Com o EasyRent, sua empresa pode oferecer serviços de aluguel de carros sem a necessidade de cartão de crédito para caução e reembolso, eliminando a burocracia desnecessária. É importante destacar que esse serviço contempla apenas usuários cadastrados ativos em nossa plataforma, garantindo segurança e confiabilidade nas transações. Além disso, temos parceria com as maiores locadoras em todo território nacional, garantindo uma ampla variedade de veículos e qualidade de serviço. Com inúmeros pontos acessíveis de retirada de veículos, como aeroportos, shoppings e grandes centros urbanos, seus clientes podem desfrutar de uma experiência de aluguel de carros sem estresse, retirando os veículos de forma rápida e fácil. Além disso, sua empresa receberá pagamentos por boleto dentro do mês, garantindo uma gestão financeira eficiente. Simplifique sua operação, melhore a experiência do cliente e impulsione sua eficiência financeira com o EasyRent da Atise. Entre em contato conosco hoje mesmo para saber mais!" class="w-100"/>
    </div>
  </div>
</section>
 */}

{/* EASYRENT*/}
{/* EASYRENT*/}
{/* EASYRENT*/}

{/*TAGS*/}
{/*TAGS*/}
{/*TAGS*/}

  <section class="tags" aria-labelledby="tag-label">
  <div class="container">
    <h2 class="headline headline-2 section-title" id="title">
      <span class="span"><FaPushed /> Nossos Serviços</span>
    </h2>

    <p class="section-text">
    </p>
    <center>
    <ul>
      <li>
        <button class="card tag-btn" id="tags">
          <FaAddressCard />
         <a href="#beneficios"> <p class="btn-text">Clube de Benefícios</p></a>
        </button>
      </li>

      <li>
        <button class="card tag-btn" id="tags">
          <FaHouseDamage />

          <a href="#housecare"><p class="btn-text">Atise HouseCare</p> </a>
        </button>
      </li>

      <li>
        <button class="card tag-btn" id="tags">
          <FaHouseDamage />

          <a href="#easyrent"><p class="btn-text">Atise Rent</p> </a>
        </button>
      </li>

      <li>
        <button class="card tag-btn" id="tags">
          <FaPlaceOfWorship />

          <a href="#burial"><p class="btn-text">Atise Burial</p></a>
        </button>
      </li>

    </ul>
    </center>
  </div>

</section>



{/*TAGS*/}
{/*TAGS*/}
{/*TAGS*/}

{/*CLUBE DE VANTAGENS*/}
{/*CLUBE DE VANTAGENS*/}
{/*CLUBE DE VANTAGENS*/}
      
<section class="section feature" aria-label="feature" id="beneficios">
  <div class="container">

    <h2 class="headline headline-2 section-title" id="title">
      <span class="span"><FaPushed /> Tudo+por- </span>
    </h2>

    <p class="section-text" id="subTitle">
      Clube de vantagens tudomaispormenos.com.br
    </p>
    <div id="clube">
      <img id="imgClube"src={clube} width="1602" height="903" loading="lazy"
                alt="Self-observation is the first step of inner unfolding" class="img-cover" />
    </div>
    <a href="https://tudomaispormenos.com.br/" class="btn btn-secondary">
      <span class="span" id="clubeButton">Clube de benefícios tudomaispormenos.com.br</span>

      <ion-icon name="arrow-forward" aria-hidden="true"></ion-icon>
    </a>
  </div>
</section>

{/*MEDCARE*/}
{/*MEDCARE*/}
{/*MEDCARE*/}

{/* FOOTER*/}
{/* FOOTER*/}
{/* FOOTER*/}
{/* FOOTER*/}

<footer>
<div class="container">

  <div>

    <div class="footer-bottom">

      <p class="copyright">
        &copy; Desenvolvido por <a href="https://www.linkedin.com/in/gleison-silveira-de-freitas-04385022b/" class="copyright-link">Gleison Silveira | Atise.com.br 2024 LTDA </a>
      </p>

      <ul class="social-list">

        <li>
          <a href="https://www.instagram.com/atise_assist/" class="social-link">
            <ion-icon name="logo-instagram"></ion-icon>

            <span class="span">Instagram</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
</footer>

  {/* FOOTER*/}
  {/* FOOTER*/}
  {/* FOOTER*/}
  {/* FOOTER*/}

  {/*TOP*/}
  {/*TOP*/}
  {/*TOP*/}

<a href="#top" class="back-top-btn" aria-label="back to top" data-back-top-btn>
   <ion-icon name="arrow-up-outline" aria-hidden="true"></ion-icon>
</a>

<FloatingWhatsApp
  phoneNumber='5521975841912' 
  accountName='atise assist' 
  avatar='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.explicit.bing.net%2Fth%3Fid%3DOIP.1_qdFKoTKpRF_KwZmS46kQHaHa%26pid%3DApi&f=1&ipt=d124525a87105f78463f5c020c2ee3451f7514aca5b940d3b6ae7abbc20be559&ipo=images' // Optional
  chatMessage='Ola! Gostaria de um atendimento dos nossos operadores da atise assist' 
  statusMessage='atise.com.br' 
  placeholder='Digite sua mensagem para atise' 
  darkMode={true}
/>
</>
  );
}

export default App;
